import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFound from "../components/NotFound/NotFound"
import NotFound2 from "../components/NotFound/NotFound2"

const index = Math.floor(Math.random() * 2) + 1

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    {index > 1 ? <NotFound /> : <NotFound2 />}
  </Layout>
)

export default NotFoundPage
