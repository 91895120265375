import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link"

export default function NotFound() {
  const data = useStaticQuery(graphql`
    query {
      karl404: file(relativePath: { eq: "Karl404.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const karl404 = data.karl404.childImageSharp.fluid
  return (
    <div className="notFound-container">
      <h1 className="notFound-title">
        4<strong>0</strong>4
      </h1>
      <div className="notFound-image">
        <Img fluid={karl404} alt="please help karl find love" />
      </div>
      <div className="karl-needs-love">
        <div className="karl-title-one">Page not available.</div>
        <div className="karl-title-two">But Karl is. 😉</div>
        <p className="karl-bio">
          Karl is the founder of <strong>Ryse Network</strong> who likes long
          walks on the beach, scrapbooking, and occasionally dismantling
          capitalism.{" "}
        </p>
        <p className="no-karl">
          But if you're more interested in networking resources, try the button
          below.
        </p>
        <Link to="/">
          <button className="notFound-button">Back To Homepage</button>
        </Link>
        <p>Please help Karl find love.</p>

      </div>
    </div>
  )
}
