import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link"

export default function NotFound() {
  const data = useStaticQuery(graphql`
    query {
      businessCat: file(relativePath: { eq: "business-cat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const businessCat = data.businessCat.childImageSharp.fluid
  return (
    <div className="notFound-container">
      <h1 className="notFound-title">
        4<strong>0</strong>4
      </h1>
      <p>Shhh... our main developer is hard at work.</p>
      <div className="notFound-image">
        <Img fluid={businessCat} alt="our developers hard at work" />
      </div>
      <p>Please do not disturb. Thank you.</p>
      <Link to="/">
        <button className="notFound-button">Back To Homepage</button>
      </Link>
    </div>
  )
}
